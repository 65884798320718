import React from 'react'

function Footer() {
    return (
        <footer>
            <div className="credits">
                <img src='https://cdn.arkane-worldwide.com/assets/logo/logo_midres_wbg.png' alt='Arkane Logo'/>
                <div className="wrapper">
                    <span>&copy; 2024 - ArkaneTV</span>
                    <a href='https://link.ayrox.fr/arknvtv_campaign' target='_blank' rel='noreferrer'>
                        <span className="author">made with ♥ by Ayrox</span>
                    </a>
                </div>
            </div>
            <div className='socials'>
                <div className='container'>
                    <a href='https://www.youtube.com/@arkaneworldwide' target='_blank' rel='noreferrer'>
                        <div className='youtube'>
                            <i class='bx bxl-youtube'></i>
                        </div>
                    </a>
                    <a href='https://twitter.com/ArkaneWW' target='_blank' rel='noreferrer'>
                        <div className='twitter'>
                            <i class='bx bxl-twitter'></i>
                        </div>
                    </a>
                    <a href='https://discord.gg/arknwl' target='_blank' rel='noreferrer'>
                        <div className='discord'>
                            <i class='bx bxl-discord-alt'></i>
                        </div>
                    </a>
                    <a href='https://www.tiktok.com/@arkane.ww?lang=fr' target='_blank' rel='noreferrer'>
                        <div className='tiktok'>
                            <i class='bx bxl-tiktok'></i>
                        </div>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
