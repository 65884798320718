import React from 'react';
import { Link } from "react-router-dom";

const Stream = ({ user_name, title, viewer_count, thumbnail_url, profile_image_url }) => {
  return (
    <div className="stream-container">
      <Link to={`/redirect/${user_name}`} target={"_blank"} noreferrer>
        <div className="thumbnail-container">
          <img src={thumbnail_url} alt={`${user_name} Stream Thumbnail`} className="stream-thumbnail"/>
          <div className='viewer-count-container'>
            <img src='../images/icons/viewers.svg' className='icon-viewer-count' alt='Viewer icon'/>
            <span className="viewer-count">{viewer_count}</span>
          </div>
        </div>
      </Link>
      <div className="stream-details">
        <Link to={`/redirect/${user_name}`} target='_blank' noreferrer>
          <img src={profile_image_url} alt={`${user_name} Profile`} className="stream-profile"/>
        </Link>
        <div className="stream-info">
          <Link to={`/redirect/${user_name}`} target={"_blank"} noreferrer>
            <span className="stream-username">{user_name}</span>
          </Link>
          <Link to={`/redirect/${user_name}`} target={'_blank'} noreferrer>
            <span className="stream-title" title={title}>{title}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Stream;
