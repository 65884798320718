import React, { useState, useEffect } from "react";
import axios from "axios";

function StatsBlock() {
    const [streamersCount, setStreamersCount] = useState(0);
    const [totalViewers, setTotalViewers] = useState(0);

    const fetchStreamers = async () => {
        try {
            const response = await axios.get('https://backend-tv.arkane-worldwide.com/streamers');
            const streamers = response.data;
    
            if (Array.isArray(streamers) && streamers.length > 0) {
                setStreamersCount(streamers.length);
    
                const totalViewersCount = streamers.reduce((total, streamer) => {
                    return total + streamer.viewer_count;
                }, 0);
    
                setTotalViewers(totalViewersCount);
            } else {
                console.error('Les données reçues ne sont pas au format attendu.');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des streamers :', error);
        }
    };
    

    useEffect(() => {
        fetchStreamers();
        const interval = setInterval(() => {
            fetchStreamers();
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="stats">
            <div className="streams-count">
                <div className="left">
                    <img src="../images/icons/live.svg" alt="Icone de streams en direct" draggable="false" />
                    <span>Streamers en live</span>
                </div>
                <div className="right">
                    <span>{streamersCount}</span>
                </div>
            </div>
            <div className="viewers-count">
                <div className="left">
                    <img src="../images/icons/viewers.svg" alt="Icone de viewers en direct" draggable="false" />
                    <span>Nombre de spectateurs</span>
                </div>
                <div className="right">
                    <span>{totalViewers}</span>
                </div>
            </div>
        </div>
    );
}

export default StatsBlock;
