import React from "react";
import "../styles/pages/App.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TopPart from "../components/TopPart";


function App() {

    return (
        <div className="App">
            <Header />
                <main>
                    <TopPart />
                </main>
            <Footer />
        </div>
    );
}

export default App;