import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useEffect } from "react";

function RedirectPage() {
    const { twitch_name } = useParams();

    useEffect(() => {
        setTimeout(() => {
            window.location.href = `https://twitch.tv/${twitch_name}`;
        }, 500)
    }, [twitch_name]);

    return (
        <>
            <Header />
                <main>
                    <span>Redirection vers twitch.tv/{twitch_name}</span>
                </main>
            <Footer />
        </>
    )
}

export default RedirectPage