import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Stream from './Stream';

const StreamersList = ({ searchTerm }) => {
  const [streamers, setStreamers] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://backend-tv.arkane-worldwide.com/streamers');
      setStreamers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Erreur lors de la récupération des streamers :', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <h2 className='loading-text'><i className='bx bx-loader-alt bx-spin bx-flip-vertical'></i> Chargement des streamers en cours...</h2>;
  }

  return (
    <div className="streams-list">
        {Array.isArray(streamers) && streamers.length > 0 ?
            streamers
                .filter((streamer) => {
                    const tempTitle = `${streamer.title}`;
                    const tempUsername = `${streamer.user_name}`;
                    return tempTitle.toLowerCase().includes(searchTerm.toLowerCase()) || tempUsername.toLowerCase().includes(searchTerm.toLowerCase());
                })
                .map((streamer, index) => (
                    <Stream
                        key={index}
                        user_name={streamer.user_name}
                        title={streamer.title}
                        viewer_count={streamer.viewer_count}
                        thumbnail_url={streamer.thumbnail_url}
                        profile_image_url={streamer.profile_image_url}
                    />
                ))
            : <h3>Aucun streamer en stream sur Arkane (reviens vers 21h pour trouver ton bonheur <img src='https://cdn.discordapp.com/emojis/1167158633453334528.png' draggable="false" height={"25"} alt='Arkane Suporter'/>).</h3>
        }
    </div>
  );

};

export default StreamersList;
