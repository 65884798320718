import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./pages/App.js";
import RedirectPage from "./pages/RedirectPage.js";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />
    },
    {
        path: "redirect/:twitch_name",
        element: <RedirectPage />
    }
]);

export function Router() {
    return <RouterProvider router={router} />
}