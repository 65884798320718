import React from 'react'

function Header() {
    return (
        <>
            <header>
                <div className='goback-website'>
                    <a href='https://arkane-worldwide.com'>
                        <span>
                            <i class='bx bx-left-arrow-alt'></i> Retourner au site
                        </span>
                    </a>
                </div>
                <div className='logo-header'>
                    <a href='https://arkane-worldwide.com'>
                        <div className="logo">
                            <img src="https://cdn.arkane-worldwide.com/assets/logo/logo_midres_wbg.png" alt="Logo Arkane" />
                        </div>
                    </a>
                </div>
                <div className='empty'>
                    
                </div>
            </header>
        </>
    )
}

export default Header