import React, { useState } from "react";
import StatsBlock from "./StatsBlock";
import StreamersList from "./StreamerList";

function TopPart() {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearch = (e) => {
        let value = e.target.value
        setSearchTerm(value);
    };

    return (
        <div className={"top-part"}>
            <div className={"title"}>
                <h1>Arkane TV</h1>
                <h3>Regardez en direct les streamers/euses de Arkane !</h3>
            </div>
            <div className={"search-bar"}>
                <i className='bx bx-search'></i>
                <input
                    type="text"
                    placeholder="Rechercher un streamer, un mot-clé, un tag..."
                    title="Rechercher un streamer, un mot-clé, un tag"
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>
            <StatsBlock />
            <StreamersList searchTerm={searchTerm} />
        </div>
    )
}

export default TopPart;
